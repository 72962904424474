import { createSlice } from '@reduxjs/toolkit';
import http from '../shared_client_utils/http';

const initialState = {
  getStartedId: '',
  userId: '',
  isComplete: '',
};

const getStarted = createSlice({
  name: 'getStarted',
  initialState,
  reducers: {
    completeChangeSalesSettings: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const {
  completeChangeSalesSettings,
} = getStarted.actions;

export default getStarted.reducer;

export const loadGetStartedStep = (userId) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/get-started/${userId}`, config);
  return dispatch(completeChangeSalesSettings(response));
};

export const updateGetStartedStep = (data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/get-started`, config);
  return dispatch(completeChangeSalesSettings(response));
};