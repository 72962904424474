import {
  Grid,
} from '@mui/material';
import {
  Flag as FlagIcon,
  ThumbDownAlt as ThumbDownIcon,
  FiberNewOutlined as FiberNewIcon,
  Comment as CommentIcon,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import Tooltip from './Tooltip';

const useStyles = makeStyles()(theme => ({
  root: {
    width: 'auto',
  },
  icon: {
    fill: 'inherit',
    fontSize: 'inherit',
  },
  clientNoteBox: {
    marginRight: 4
  },
  newIcon: {
    fill: theme.palette.primary.main,
    fontSize: 'inherit',
    transform: 'scale(1.5)',
    marginRight: '4px',
  },
  flagsBox: {
    height: 'fit-content',
    cursor: 'pointer',
  },
  noShowsBox: {
    width: 'auto',
    marginLeft: '0.1em',
    lineHeight: 'initial',
    height: 'fit-content',
  },
  noShowsNumber: {
    color: '#000000',
  },
}));

const formatNoShowsTitle = (number, name) => {
  if (number === 1) {
    return `${name} has failed to show once`;
  }
  return `${name} has failed to show ${number} times`;
}

const ClientIcons = (props) => {
  const { flags, noShows, name, disableNoShowsNumber, className, isClientNew, leftNote, isOldWaiver, handleTabChange } = props;
  const {classes} = useStyles();

  const isFlagged = (flags && flags.length > 0) || isOldWaiver;

  const noShowsNumber = Number(noShows);
  const noShowsTitle = formatNoShowsTitle(noShowsNumber, name);

  return (
    <Grid
      container
      className={className}
      classes={{
        root: classes.root,
      }}
    >
      {leftNote && (
        <Tooltip title="Client left a note">
          <Grid item className={classes.clientNoteBox} data-testclass="leftNoteIcon">
            <CommentIcon className={classes.icon} />
          </Grid>
        </Tooltip>
      )}
      {isClientNew && (
        <Tooltip title="New client">
          <Grid item data-testclass="newClientIcon">
            <FiberNewIcon className={classes.newIcon} />
          </Grid>
        </Tooltip>
      )}
      {isFlagged && (
        <Tooltip title={isOldWaiver ? 'Client requires an updated Intake form' : flags}>
          <Grid item className={classes.flagsBox} data-testclass="flagIcon">
            <FlagIcon className={classes.icon} onClick={handleTabChange ? (e) => handleTabChange(e, 'alerts') : null}/>
          </Grid>
        </Tooltip>
      )}
      {noShowsNumber > 0 && (
        <Tooltip title={noShowsTitle}>
          <Grid container className={classes.noShowsBox}>
            <Grid item data-testclass="noShowIcon">
              <ThumbDownIcon className={classes.icon} />
            </Grid>
            {!disableNoShowsNumber && (
              <Grid item className={classes.noShowsNumber}>
                {noShowsNumber}
              </Grid>
            )}
          </Grid>
        </Tooltip>
      )}
    </Grid>
  );
};

ClientIcons.defaultProps = {
  className: '',
};

export default ClientIcons;
