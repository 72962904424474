import { Component, createElement, cloneElement } from 'react';
import moment from 'moment';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  rbcAddonsPaddings: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  paddings: {
    position: 'absolute',
    width: '100%',
  },
});

const stringifyPercent = (v) => {
  return typeof v === 'string' ? v : `${v}%`;
}
const minutesFromDate = (datetime, start, localizer) => {
  return localizer.diff(start, datetime, 'minutes');
}
// Most of the code below is similar like from
// https://github.com/jquense/react-big-calendar/blob/v0.32.0/src/utils/TimeSlots.js
const calculateHeight = (options) => {
  const {
    paddingStart,
    paddingEnd,
    start,
    end,
    step,
    timeslots,
    localizer,
  } = options;

  const totalMinutes = 1 + localizer.diff(start, end, 'minutes');
  const numGroups = totalMinutes / (step * timeslots);
  const numSlots = numGroups * timeslots;

  const startMinutes = minutesFromDate(paddingStart, start, localizer);
  const endMinutes = minutesFromDate(paddingEnd, start, localizer);

  const topPosition = (startMinutes / (step * numSlots)) * 100;
  const height = (endMinutes / (step * numSlots)) * 100 - topPosition;

  return (height < 0) ? height * -1 : height;
};

const PaddingTime = (props) => {
  const {
    step,
    timeslots,
    classes,
    paddingTime,
    paddingStart,
    paddingEnd,
    getPositionStyle,
    localizer,
    event: { paddingColor, start, end },
  } = props;

  const height = calculateHeight({
    paddingStart,
    paddingEnd,
    start,
    end,
    step,
    timeslots,
    localizer,
  });
  const stringifiedHeight = stringifyPercent(height);
  const positionStyle = getPositionStyle(stringifiedHeight);

  return (
    <div
      className={classes.paddings}
      title={`${paddingTime} min padding`}
      style={{
        ...positionStyle,
        height: stringifiedHeight,
        backgroundColor: paddingColor,
      }}
    />
  );
};

const mergeEventWripperComponent = (components, classes, parentThis) => {
  const eventWrapper = ({ children, ...props }) => {
    const { event: { paddingBefore, paddingAfter, start, end } } = props;
    const { step, timeslots, localizer } = parentThis.props;

    const fullStart = moment(start).subtract(paddingBefore, 'minutes').toDate();
    const fullEnd = moment(end).add(paddingAfter, 'minutes').toDate();

    const newProps = {};
    newProps.children = (
      <div className={classes.rbcAddonsPaddings}>
        {(paddingBefore > 0) && (
          <PaddingTime
            {...props}
            step={step}
            timeslots={timeslots}
            paddingTime={paddingBefore}
            paddingStart={fullStart}
            paddingEnd={start}
            classes={classes}
            localizer={localizer}
            getPositionStyle={(height) => ({ top: `-${height}` })}
          />
        )}
        {children.props.children}
        {(paddingAfter > 0) && (
          <PaddingTime
            {...props}
            step={step}
            timeslots={timeslots}
            paddingTime={paddingAfter}
            paddingStart={end}
            paddingEnd={fullEnd}
            classes={classes}
            localizer={localizer}
            getPositionStyle={(height) => ({ bottom: `-${height}` })}
          />
        )}
      </div>
    );
    newProps.className = children.props.className;
    const newChildren = cloneElement(children, newProps);

    return createElement(components.eventWrapper, props, newChildren);
  };

  return { ...components, eventWrapper };
}

const withPaddings = (Calendar) => {
  class PaddingsCalendar extends Component {
    constructor(props) {
      super(props);

      const { components, classes } = props;

      this.components = mergeEventWripperComponent(components, classes, this);
    }

    render() {
      const { classes, ...props } = this.props;

      return (
        <Calendar
          {...props}
          components={this.components}
        />
      );
    }
  }

  return withStyles(PaddingsCalendar, styles);
};

export default withPaddings;
