import bookingQuestionSettings from '../configs/bookingQuestionSettings';

export const checkServiceQuestionForCalendar = (service) => {
  return (
    service && service.bookingQuestion &&
    [
      bookingQuestionSettings.onlineAndCalendar.status,
      bookingQuestionSettings.calendar.status,
    ].includes(service?.bookingQuestionSetting)
  );
};
