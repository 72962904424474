import { useState, useRef, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';
import Textarea from '../../../../shared_components/Textarea';

const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
  },
  questionBox: {
    maxWidth: '300px',
  },
  textarea: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  buttonBox: {
    marginTop: theme.spacing(15/8),
    justifyContent: 'space-between',
  },
}));

const ServiceQuestion = (props) => {
  const {
    onClose,
    appointment: { serviceBookingQuestion },
    saveServiceBookingAnswerAndProceed,
  } = props;

  const {classes} = useStyles();
  const [answer, setAnswer] = useState('');
  const [answerError, setAnswerError] = useState(false);

  const answerInput = useRef(null);
  useEffect(() => answerInput.current.focus(), []);

  const handleSave = () => {
    if (!answer) {
      setAnswerError(true);
      return;
    }

    saveServiceBookingAnswerAndProceed(answer);
  }

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <Typography color="secondary" variant="subtitle1">
          Ask customer the question below
        </Typography>
      </Grid>
      <Grid item className={classes.questionBox}>
        {serviceBookingQuestion}
      </Grid>
      <Textarea
        id="serviceBookingAnswer"
        name="serviceBookingAnswer"
        value={answer}
        onChange={({ target }) => setAnswer(target.value)}
        minRows="5"
        className={classes.textarea}
        error={answerError}
        inputRef={answerInput}
      />
      <Grid container className={classes.buttonBox}>
        <GreyButton
          variant="text"
          size="small"
          onClick={onClose}
        >
          Cancel
        </GreyButton>
        <GreenButton
          variant="contained"
          size="small"
          onClick={handleSave}
        >
          Save
        </GreenButton>
      </Grid>
    </Grid>
  );
};

export default ServiceQuestion;
