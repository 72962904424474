/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import {
  Grid,
  FormControl,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';

import GreyButton from '../../../shared_components/buttons/Grey';
import GreenButton from '../../../shared_components/buttons/Green';
import RedButton from '../../../shared_components/buttons/Red';
import Select from './Select';
import Textarea from '../../../shared_components/Textarea';
import {
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
} from '../../../shared_components/CustomDialog';
import {
  formatDate,
  prepareBusyTimesArray,
  prepareBusyTimesDurationsArray,
  convertDateTimeIntoDateAndTime,
  getStartAndEndTimeInBusinessTz,
} from '../../../shared_client_utils/dateUtils';
import { validateObjectByMap } from '../../../shared_client_utils/formUtils';
import { BusyTimesApi } from '../../../client_http_api';
import ConfirmDialog from './ConfirmDialog';
import InputLabel from './InputLabel';

const useStyles = makeStyles()((theme) => ({
  busyTimeInfo: {
    flexDirection: 'column',
    padding: theme.spacing(),
    fontSize: theme.spacing(7 / 4),
    backgroundColor: grey[100],
    color: grey[800],
  },
  busyTimeInfoItem: {
    flexDirection: 'column',
    marginBottom: theme.spacing(),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  itemTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
  },
  busyTimeFields: {
    flexDirection: 'column',

    '& > div': {
      marginTop: theme.spacing(2),
    },
  },
  timeBox: {
    width: '130px',
    marginRight: theme.spacing(4),

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  label: {
    paddingBottom: theme.spacing(),
  },
  inputRoot: {},
  buttonBox: {
    justifyContent: 'space-between',
  },
  rightButtons: {
    justifyContent: 'flex-end',
  },
  saveButton: {
    marginRight: theme.spacing(2),
  },
  backdrop: {
    borderRadius: '7px',
    backgroundColor: 'rgb(0, 0, 0, 0.2)',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const initializeBusyTime = (options) => ({
  locationId: '',
  locationName: '',
  staffId: '',
  staffName: '',
  date: new Date(),
  time: 0,
  duration: 30,
  description: '',
  ...options
});

const busyTimeErrorsMap = {
  date: {
    isValid: value => !!value,
  },
  time: {
    isValid: value => value && value > 0,
  },
};

const prepareBusyTime = (busyTime, creatorId, moment) => {
  const { date, time, duration, ...restBusyTime } = busyTime;
  const [startTime, endTime] = getStartAndEndTimeInBusinessTz({
    date,
    time,
    duration,
    moment,
  });

  return {
    ...restBusyTime,
    creatorId,
    duration,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    utcOffset: startTime.utcOffset(),
  };
};

const AddBusyTime = (props) => {
  const {
    auth,
    staff,
    onClose,
    selectedEvent,
    selectedLocation,
    clickedSlotDate,
    clickedSlotStaffId,
    slotStep,
    calendarSettings,
    business = {}
  } = props;
  const { timezone } = business;
  const {classes} = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [isEditAction, setEditAction] = useState(false);

  const [selectedStaff, setSelectedStaff] = useState(() => {
    return staff.find(({ id }) => id === clickedSlotStaffId);
  });

  const [busyTime, setBusyTime] = useState({});
  const [busyTimeErrors, setBusyTimeErrors] = useState({});

  const timeOptions = useMemo(() => prepareBusyTimesArray(slotStep, timezone, busyTime), [slotStep, timezone, busyTime]);
  const durationOptions = useMemo(() => prepareBusyTimesDurationsArray(calendarSettings?.intervals, timezone, busyTime), [calendarSettings?.intervals, timezone, busyTime]);

  useEffect(() => {
    if (selectedEvent) {
      const [date, time] = convertDateTimeIntoDateAndTime(
        selectedEvent.start,
        moment,
      );
      setBusyTime({
        ...selectedEvent,
        date,
        time,
      });
      setEditAction(true);
      return;
    }

    const [date, time] = convertDateTimeIntoDateAndTime(
      clickedSlotDate,
      moment,
    );
    const newBusyTime = initializeBusyTime({
      date,
      time,
      locationId: selectedLocation.id,
      locationName: selectedLocation.name,
      staffId: selectedStaff.id,
      staffName: selectedStaff.fullName,
    });
    setBusyTime(newBusyTime);
  }, []);

  const handleChangeBusyTime = (name, value) => {
    setBusyTime((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleCreateBusyTime = async () => {
    const { onUpdateEvent, handleDisplayFlashMessage } = props
    const { isValid, errors } = validateObjectByMap(busyTime, busyTimeErrorsMap);
    if (!isValid) {
      setBusyTimeErrors(errors);
      return;
    }
    setBusyTimeErrors({});
    setLoading(true);

    try {
      const preparedBusyTime = prepareBusyTime(
        busyTime,
        auth.currentStaff.id,
        moment,
      );
      await BusyTimesApi.create(preparedBusyTime, auth);

      onUpdateEvent();
      setLoading(false);
      onClose();
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      setLoading(false);
      onClose();
    }
  };

  const handleSaveBusyTime = async () => {
    const { onUpdateEvent, handleDisplayFlashMessage } = props;
    const { isValid, errors } = validateObjectByMap(busyTime, busyTimeErrorsMap);
    if (!isValid) {
      setBusyTimeErrors(errors);
      return;
    }
    setBusyTimeErrors({});
    setLoading(true);

    try {
      const preparedBusyTime = prepareBusyTime(
        busyTime,
        auth.currentStaff.id,
        moment,
      );
      await BusyTimesApi.update(preparedBusyTime.id, preparedBusyTime, auth);

      onUpdateEvent();
      setLoading(false);
      onClose();
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      setLoading(false);
      onClose();
    }
  }

  const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false);

  const handleDeleteBusyTime = async () => {
    const { onUpdateEvent, handleDisplayFlashMessage } = props;
    setLoading(true);

    try {
      await BusyTimesApi.delete(busyTime.id, auth);

      onUpdateEvent();
      setLoading(false);
      onClose();
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage(error?.message || 'Unexpected error, please try again', 'error');
      }
      setLoading(false);
      onClose();
    }
  }

  const selectedTime = timeOptions.find(({ value }) => {
    return value === busyTime.time;
  });
  const selectedDuration = durationOptions.find(({ value }) => {
    return value === busyTime.duration;
  });

  return (
    <>
      <CustomDialogTitle onClose={onClose}>
        Busy time
      </CustomDialogTitle>

      <CustomDialogContent>
        <Grid container className={classes.busyTimeInfo}>
          <Grid container className={classes.busyTimeInfoItem}>
            <Grid item className={classes.itemTitle}>Staff</Grid>
            <Grid item>{busyTime.staffName}</Grid>
          </Grid>

          <Grid container className={classes.busyTimeInfoItem}>
            <Grid item className={classes.itemTitle}>Location</Grid>
            <Grid item>{busyTime.locationName}</Grid>
          </Grid>

          <Grid container className={classes.busyTimeInfoItem}>
            <Grid item className={classes.itemTitle}>Date</Grid>
            <Grid item>{busyTime.date && formatDate(busyTime.date)}</Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.busyTimeFields}>
          <Grid container>
            <FormControl className={classes.timeBox}>
              <InputLabel
                shrink
                htmlFor="time"
                className={classes.label}
                focused={false}
              >
                From
              </InputLabel>
              <Select
                id="time"
                onChange={({ value }) => handleChangeBusyTime('time', value)}
                className={classes.inputRoot}
                options={timeOptions}
                value={selectedTime}
              />
            </FormControl>

            <FormControl className={classes.timeBox}>
              <InputLabel
                shrink
                htmlFor="duration"
                className={classes.label}
                focused={false}
              >
                Duration
              </InputLabel>
              <Select
                id="duration"
                onChange={({ value }) => {
                  return handleChangeBusyTime('duration', value);
                }}
                className={classes.inputRoot}
                options={durationOptions}
                value={selectedDuration}
              />
            </FormControl>
          </Grid>

          <FormControl>
            <InputLabel
              shrink
              htmlFor="description"
              className={classes.label}
              focused={false}
            >
              Description
            </InputLabel>
            <Textarea
              id="description"
              name="description"
              value={busyTime.description}
              onChange={({ target }) => {
                return handleChangeBusyTime('description', target.value);
              }}
              minRows="5"
              classes={{
                root: classes.inputRoot,
              }}
            />
          </FormControl>
        </Grid>
      </CustomDialogContent>

      <CustomDialogActions className={classes.buttonBox}>
        <Grid container>
          {isEditAction && (
            <RedButton
              variant="contained"
              size="small"
              onClick={() => setDeleteDialogOpened(true)}
            >
              Delete
            </RedButton>
          )}
        </Grid>

        <Grid container className={classes.rightButtons}>
          <GreenButton
            variant="contained"
            size="small"
            onClick={isEditAction ? handleSaveBusyTime : handleCreateBusyTime}
            className={classes.saveButton}
          >
            Save
          </GreenButton>
          <GreyButton
            variant="contained"
            size="small"
            onClick={onClose}
          >
            Cancel
          </GreyButton>
        </Grid>
      </CustomDialogActions>

      <ConfirmDialog
        title="Delete busy time?"
        open={isDeleteDialogOpened}
        onClose={() => setDeleteDialogOpened(false)}
        onCancel={() => setDeleteDialogOpened(false)}
        onSuccess={handleDeleteBusyTime}
      />

      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export default AddBusyTime;
