import { navigate } from '@nlevchuk/react-big-calendar/lib/utils/constants';
import TimeGrid from '@nlevchuk/react-big-calendar/lib/TimeGrid';

import { filterSelectedStaffForCalendar } from '../../utils/staffUtils';

const WeekView = (props) => {
  const { date, staff, selectedStaffId, localizer } = props;
  const range = WeekView.range(date, { localizer });

  const filteredStaff = filterSelectedStaffForCalendar(staff, selectedStaffId);
  const resources = filteredStaff.map((staff) => ({
    id: staff.id,
    title: staff.alias,
  }));

  if (resources.length === 0) {
    return <div>There are no available times for selected parameters</div>;
  }
  
  return (
    <TimeGrid
      {...props}
      localizer={localizer}
      range={range}
      resources={resources}
    />
  );
};

WeekView.range = (date, { localizer }) => {
  const firstOfWeek = localizer.startOfWeek();
  const start = localizer.startOf(date, 'week', firstOfWeek);
  const end = localizer.endOf(date, 'week', firstOfWeek);

  return localizer.range(start, end);
}

WeekView.navigate = (date, action, { localizer }) => {
  switch (action){
    case navigate.PREVIOUS:
      return localizer.add(date, -1, 'week');
    case navigate.NEXT:
      return localizer.add(date, 1, 'week');
    default:
      return date;
  }
}

WeekView.title = (date, { localizer }) => {
  const [start, ...rest] = WeekView.range(date, { localizer });
  return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
}

export default WeekView;
