import moment from 'moment';

import { getDate } from '../../../shared_client_utils/dateUtils';
import dayShiftTypes from '../configs/dayShiftTypes';
import { timeDiffInMinutes } from '../../../shared_client_utils/momentUtils';

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const filterSelectedStaffForCalendar = (staff, staffId) => {
  return staff.filter(({ id }) => (staffId ? (id === staffId) : true));
};

export const filterServicesByStaff = (services, { staffServices = [] }) => {
  const availableServiceIds = staffServices.map(({ ServiceId }) => ServiceId);

  return services.filter(({ id }) => availableServiceIds.includes(id));
};

export const areEventBordersIncludedInStaffWorkingHours = (options) => {
  const { start, end, dayShifts, moment } = options;

  const currentTime = moment(start);
  const startTime = timeDiffInMinutes(currentTime, { moment });
  const endTime = timeDiffInMinutes(end, { moment });

  const date = getDate(currentTime);
  const dayShift = dayShifts?.find(shift => shift.date === date);

  return [dayShiftTypes.scheduled.type, dayShiftTypes.modified.type].includes(dayShift?.type) &&
    startTime >= dayShift?.beginTime &&
    endTime <= dayShift?.endTime;
};

export const areEventBordersIncludedInLocationWorkingHours = (options) => {
  const { start, end, beginTime, endTime, moment } = options;

  const currentTime = moment(start);
  const startMinutes = timeDiffInMinutes(currentTime, { moment });
  const endMinutes = timeDiffInMinutes(end, { moment });

  return startMinutes >= beginTime && endMinutes <= endTime;
};

export const isSelectedTimeIncludedInStaffWorkingHours = (options) => {
  const { start, dayShifts, step, moment } = options;

  const selectedTime = moment(start);
  const minutes = timeDiffInMinutes(selectedTime, { moment });

  const date = getDate(selectedTime);
  const dayShift = dayShifts?.find((shift) => {
    return shift.date === date;
  });
  if (!dayShift) {
    return;
  }
  // { type, beginTime, endTime }

  // https://sentry.hivemanager.io/sentry/hivemanager/issues/591
  // I can't reproduce the issue neither locally nor in production
  return [dayShiftTypes?.scheduled?.type, dayShiftTypes?.modified?.type].includes(dayShift?.type) &&
        minutes >= dayShift?.beginTime &&
        minutes <= (dayShift?.endTime - step);
};

export const isSelectedTimeIncludedInLocationWorkingHours = (options) => {
  const { start, beginTime, endTime, step, moment } = options;

  const selectedTime = moment(start);
  const minutes = timeDiffInMinutes(selectedTime, { moment });

  return minutes >= beginTime && minutes <= (endTime - step);
};

export const doesStaffHaveActiveService = (staff, serviceId) => {
  return staff.staffServices.find(({ ServiceId }) => ServiceId === serviceId);
};

export const formatStaffDatetime = (datetimeTz) => {
  return moment(datetimeTz).format('DD.MM.YYYY');
};
