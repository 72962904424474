/*
Duplicate file server/day-shift-types.js
*/
export default {
  notWorking: {
    name: 'Not working',
    type: 'notWorking',
  },
  scheduled: {
    name: 'Scheduled',
    type: 'scheduled',
  },
  modified: {
    name: 'Modified',
    type: 'modified',
  },
  dayOff: {
    name: 'Day off',
    type: 'dayOff',
  },
  timeOffRequest: {
    name: 'Time off request',
    type: 'timeOffRequest',
  },
  freeShift: {
    name: 'Free shift',
    type: 'freeShift',
  },
  pickUpRequest: {
    name: 'Pick up shift request',
    type: 'pickUpRequest',
  },
};
