import {
  AppBar,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import {
  Menu as MenuIcon,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { navbarHeightMultiplier, maxWidth } from '../../../../shared_client_utils/theme';
import GreenButton from '../../../../shared_components/buttons/Green';
import GreyButton from '../../../../shared_components/buttons/Grey';

const useStyles = makeStyles()(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: theme.spacing(navbarHeightMultiplier),
    overflow: 'hidden',
  },
  content: {
    maxWidth,
    padding: theme.spacing(0, 3),
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  left: {
    width: 'auto',
    flexWrap: 'nowrap',
  },
  right: {
    width: 'auto',
  },
  changeTitleText: {
    flexDirection: 'column',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(),

    [theme.breakpoints.up(1050)]: {
      display: 'none',
    },
  },
  menuIcon: {
    color: '#ffffff',
  },
}));

const ChangeNavbar = (props) => {
  const { onApply, onCancel, handleOpenLeftSidebar } = props;
  const {classes} = useStyles();

  return (
    <AppBar
      position="fixed"
      className={classes.root}
    >
      <Grid container className={classes.content}>
        <Grid container className={classes.left}>
          <Grid item>
            <IconButton
              onClick={handleOpenLeftSidebar}
              className={classes.menuButton}
              size="large">
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Grid>
          <Grid container className={classes.changeTitleText}>
            <Grid item>
              <Typography variant="h6">Change appointment</Typography>
            </Grid>
            <Grid item>You can change location, staff, date and time.</Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.right}>
          <GreenButton
            variant="contained"
            size="small"
            onClick={onApply}
            className={classes.button}
          >
            Apply
          </GreenButton>
          <GreyButton
            variant="contained"
            size="small"
            onClick={onCancel}
            className={classes.button}
          >
            Cancel
          </GreyButton>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default ChangeNavbar;
