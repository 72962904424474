import { Navigate } from '@nlevchuk/react-big-calendar';
import TimeGrid from '@nlevchuk/react-big-calendar/lib/TimeGrid';

import { filterSelectedStaffForCalendar } from '../../utils/staffUtils';

const DayView = (props) => {
  const { date, staff, selectedStaffId, localizer } = props;
  const range = DayView.range(date, { localizer });

  const filteredStaff = filterSelectedStaffForCalendar(staff, selectedStaffId);
  const resources = filteredStaff.map((staff) => ({
    id: staff.id,
    title: staff.alias,
  }));

  if (resources.length === 0) {
    return <div data-testid="calendarDayNoTimes">There are no available times for selected parameters</div>;
  }
  
  return (
    <TimeGrid
      {...props}
      localizer={localizer}
      range={range}
      resources={resources}
    />
  );
};

DayView.range = (date, { localizer }) => [localizer.startOf(date, 'day')];

DayView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'day');
    case Navigate.NEXT:
      return localizer.add(date, 1, 'day');
    default:
      return date;
  }
}

DayView.title = (date, { localizer }) => {
  return localizer.format(date, 'dayHeaderFormat')
};

export default DayView;
