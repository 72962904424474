/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import moment from 'moment';
import {
  Grid,
  FormControl,
  Typography,
  Hidden,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';

import PhoneInput from '../../../../shared_components/PhoneInput';
import ServiceRow from './ServiceRow';
import { prepareOptions } from '../../../../shared_client_utils/formUtils';
// import Select from '../Select';
import SearchInput, { SearchItem, SearchCell } from '../SearchInput';
import { SearchApi } from '../../../../client_http_api';
import OutstandingBalanceStrip from '../OutstandingBalanceStrip';
import { isPositive } from '../../../../shared_client_utils/moneyUtils';
import OutlinedInput from '../../../../shared_components/Input';

const useStyles = makeStyles()((theme) => ({
  locationAndClientInfo: {
    padding: theme.spacing(0, 3),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
  inputRoot: {
    width: '100%',

    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  locationAndDate: {
    marginTop: theme.spacing(),
    alignItems: 'center',
    width: '60%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  headerBox: {
    flex: 1,
    fontSize: '18px',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginRight: 0,
    },

    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      flex: 'unset',
      width: 'auto',
    },
  },
  title: {
    color: '#808190',
    fontSize: '20px',
    lineHeight: '26px',
    marginBottom: theme.spacing(),
  },
  customerTitle: {
    marginTop: '.6rem',
  },
  customerContent: {
    width: '60%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  customerRow: {
    width: '100%',
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  amountOwingStripBox: {
    alignItems: 'center',
    flex: 1,
  },
  amountOwingInlineBox: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  inputBox: {
    display: 'flex',
    flex: 1,
    marginRight: theme.spacing(2),

    '&:last-of-type': {
      marginRight: 0,
      marginBottom: 0,
    },

    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
      flex: 'unset',
      width: '100%',
    },
  },
  searchBar: {
    zIndex: 10,
  },
  labelService: {
    color: '#808290',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '12px 12px -12px 0px',
    fontWeight: '600'
  },
  servicesContent: {
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 3),
    backgroundColor: grey[50],

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  serviceRow: {
    marginTop: '8px',
  },
  overlapMessageBox: {
    fontFamily: 'Roboto',
    color: '#808190',
    marginTop: '15px',
  },
  errorText: {
    color: 'red',
  },
  errorLine: {
    backgroundColor: '#f69988',
  },
  warningLine: {
    backgroundColor: '#fedc56',
  },
}));

const getSeverityClassName = (severity) => {
  if (severity === 'warning') {
    return 'warningLine';
  }

  return 'errorLine';
};

const Details = (props) => {
  const {
    auth,
    locations,
    client: {
      outstandingBalanceMoney,
      ...client
    },
    handleChangeClient,
    handleSelectClient,
    isDisabledClientFields,
    handleEnableClientFields,
    // isEditAction,
    // handleChangeLocation,
    onClickCreateAppointment,
    clientErrors,
    isServiceLineError,
    isServiceLineMessage,
    serviceLineSeverity,
    ...restProps
  } = props;
  const { appointment } = restProps;

  const {classes, cx} = useStyles();

  const locationOptions = prepareOptions(locations.byId);
  const selectedLocation = locationOptions.find(
    ({ id }) => id === appointment.locationId
  );

  const severityClassName = getSeverityClassName(serviceLineSeverity);

  const getSearch = async (value) => {
    const { handleDisplayFlashMessage } = props;
    let res = [];
    try {
      res = await SearchApi.searchClients(value, auth)
    } catch (error) {
      if (error?.message?.includes('Session is expired')) {
        handleDisplayFlashMessage('Session is expired, refresh the page please', 'error')
      } else {
        handleDisplayFlashMessage('Unexpected error, please try again', 'error');
      }
    }
    return res;
  }
  return (
    <>
      <Grid item className={classes.locationAndClientInfo}>
        <Grid
          container
          className={classes.locationAndDate}
        >
          <Grid container className={classes.headerBox}>
            <Typography className={classes.title}>
              Location
            </Typography>
            <Grid container>
              {selectedLocation && selectedLocation.label}
            </Grid>
          </Grid>

          <Grid container className={classes.headerBox}>
            <Typography className={classes.title}>
              Date
            </Typography>
            <Grid container>
              {appointment.date && moment(appointment.date).format('ll')}
            </Grid>
          </Grid>
        </Grid>

        <Typography
          className={cx(classes.title, classes.customerTitle)}
        >
          Customer
        </Typography>
        <Grid className={classes.customerContent}>
          <Grid container className={classes.customerRow}>
            <FormControl
              className={cx(classes.inputBox, classes.searchBar)}
            >
              <SearchInput
                showCreateLink
                clearResultsOnClick
                placeholder="Search"
                disabled={!appointment.isChangeable}
                onSearch={getSearch}
                onResultLineClick={handleSelectClient}
                onCreateLineClick={handleEnableClientFields}
                rootComponentProps={{
                  classes: {
                    root: classes.inputRoot,
                  },
                }}
                onOpenPopper={({ isLoading, isDropdownOpened }) => {
                  return isLoading || isDropdownOpened;
                }}
              >
                {(result, { onClick }) => (
                  <SearchItem onClick={onClick}>
                    <SearchCell>{result.fullName}</SearchCell>
                    <SearchCell align="right">
                      {result.mobileNumber}
                    </SearchCell>
                  </SearchItem>
                )}
              </SearchInput>
            </FormControl>
            {isPositive(outstandingBalanceMoney) && (
              <Hidden mdDown>
                <Grid
                  container
                  className={cx(
                    classes.amountOwingStripBox,
                    classes.amountOwingInlineBox,
                  )}
                >
                  <OutstandingBalanceStrip
                    amount={outstandingBalanceMoney.toFormat()}
                    clientId={client.id}
                  />
                </Grid>
              </Hidden>
            )}
          </Grid>

          <Grid container className={classes.customerRow}>
            <FormControl className={classes.inputBox}>
              <OutlinedInput
                name="firstName"
                label="First Name"
                value={client.firstName}
                onChange={({ target }) => handleChangeClient('firstName', target.value)}
                classes={{
                  root: classes.inputRoot,
                }}
                disabled={isDisabledClientFields}
                error={clientErrors.firstName}
              />
            </FormControl>
            <FormControl className={classes.inputBox}>
              <OutlinedInput
                name="lastName"
                label="Last Name"
                value={client.lastName}
                onChange={({ target }) => handleChangeClient('lastName', target.value)}
                classes={{
                  root: classes.inputRoot,
                }}
                disabled={isDisabledClientFields}
                error={clientErrors.lastName}
              />
            </FormControl>
          </Grid>

          <Grid container className={classes.customerRow}>
            <FormControl className={classes.inputBox}>
              <PhoneInput
                name="mobileNumber"
                label="Mobile Number"
                disabled={isDisabledClientFields}
                // country={countryCode}
                onChange={(value) => handleChangeClient('mobileNumber', value)}
                value={client.mobileNumber}
                error={clientErrors.mobileNumber}
                classes={{
                  root: classes.inputRoot,
                }}
              />
            </FormControl>
            <FormControl className={classes.inputBox}>
              <OutlinedInput
                name="email"
                label="Email"
                value={client.email}
                onChange={({ target }) => handleChangeClient(target.name, target.value)}
                classes={{
                  root: classes.inputRoot,
                }}
                disabled={isDisabledClientFields}
                error={clientErrors.email}
              />
            </FormControl>
          </Grid>

          <Hidden mdUp>
            <Grid container className={classes.customerRow}>
              {isPositive(outstandingBalanceMoney) && (
                <Grid container className={classes.amountOwingStripBox}>
                  <OutstandingBalanceStrip
                    amount={outstandingBalanceMoney.toFormat()}
                    clientId={client.id}
                  />
                </Grid>
              )}
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      <Grid
        container
        className={cx(
          classes.servicesContent,
          isServiceLineError ? classes[severityClassName] : '',
        )}
      >
        <Grid container className={classes.serviceRow}>
          <ServiceRow {...restProps} />
        </Grid>

        {isServiceLineError && (
          <div className={cx(
            classes.overlapMessageBox,
            (serviceLineSeverity === 'error') ? classes.errorText : '',
          )}
          >
            {isServiceLineMessage}
          </div>
        )}
      </Grid>
    </>
  );
};

export default Details;
