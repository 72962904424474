import { Checkbox as CheckboxOrigin, FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { UncheckedCheckboxIcon, CheckedCheckboxIcon } from './icons';

const useStyles = makeStyles()(theme => ({
  formControlLabelRoot: {
    marginLeft: 0,
    alignItems: 'flex-start',
  },
  formControlLabelLabel: {
    paddingTop: theme.spacing(5/8),
  },
  checkboxRoot: {
    padding: theme.spacing(),
  },
}));

const BaseCheckbox = ({ classes, error, disabled, cx, ...props }) => (
  <CheckboxOrigin
    disableRipple
    icon={
      <UncheckedCheckboxIcon error={error} />
    }
    checkedIcon={
      <CheckedCheckboxIcon
        error={error}
        disabled={disabled}
      />
    }
    color="primary"
    classes={{
      root: classes.checkboxRoot,
    }}
    disabled={disabled}
    {...props}
  />
);

const LabeledCheckbox = ({ classes, label, className, cx, ...props }) => (
  <FormControlLabel
    control={(
      <BaseCheckbox {...props} classes={classes} />
    )}
    label={label}
    className={cx(classes.formControlLabelRoot, className)}
    classes={{
      label: classes.formControlLabelLabel,
    }}
  />
);

const Checkbox = ({ label, ...props }) => {
  const {classes, cx} = useStyles();

  return label ? (
    <LabeledCheckbox {...props} label={label} classes={classes} cx={cx} />
  ) : (
    <BaseCheckbox {...props} classes={classes} cx={cx} />
  );
};

Checkbox.defaultProps = {
  label: '',
  className: '',
  disabled: false,
};

export default Checkbox;
