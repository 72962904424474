import {
  Popover,
  Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CalendarIcon, BusyIcon } from '../../../../shared_components/icons';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  time: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    fontSize: '12px',
    fontWeight: 500,
  },
  links: {
    flexDirection: 'column',
  },
  linkBox: {
    alignItems: 'center',
    marginTop: theme.spacing(),
    padding: theme.spacing(),
    color: theme.palette.secondary.main,
    fontWeight: 600,
    cursor: 'pointer',
  },
  text: {
    width: 'auto',
    marginLeft: theme.spacing(3 / 2),
  },
  iconBox: {
    width: 'auto',
  },
  icon: {
    height: theme.spacing(2),
    width: 'auto',
  },
}));

// const defaultPlacement = 'bottom';

const ClickMenu = (props) => {
  const {
    isLinkPopoverOpened,
    position,
    currentTime,
    onClickClosePopover,
    handleOpenNewAppointmentDialog,
    handleOpenNewBusyTimeDialog,
  } = props;
  const {classes} = useStyles();

  const onClickAwayOfPopover = (event) => {
    // It's workaround which prevents immediately close popper
    // after clicking on slot
    if (
      position.clientX === event.clientX
      && position.clientY === event.clientY
    ) {
      return;
    }

    onClickClosePopover();
  }

  return (
    <Popover
      open={isLinkPopoverOpened}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position.top,
        left: position.left,
      }}
      onClose={onClickAwayOfPopover}
      elevation={3}
    >
      <Grid container className={classes.root}>
        <Grid container className={classes.time}>{currentTime}</Grid>

        <Grid container className={classes.links}>
          <Grid
            container
            className={classes.linkBox}
            onClick={handleOpenNewAppointmentDialog}
          >
            <Grid container className={classes.iconBox}>
              <CalendarIcon className={classes.icon} />
            </Grid>
            <Grid item className={classes.text}>Add appointment</Grid>
          </Grid>

          <Grid
            container
            className={classes.linkBox}
            onClick={handleOpenNewBusyTimeDialog}
          >
            <Grid container className={classes.iconBox}>
              <BusyIcon className={classes.icon} />
            </Grid>
            <Grid item className={classes.text}>Add busy time</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default ClickMenu;
