import React from 'react'

import WeekView from '../WeekView'
import CustomTimeGrid from './CustomTimeGrid'
import {filterSelectedStaffForCalendar} from '../../../utils/staffUtils'

const AllRosteredCalendarView = props => {
  const { date, staff, selectedStaffId, localizer } = props
  const range = AllRosteredCalendarView.range(date, { localizer });

  const filteredStaff = filterSelectedStaffForCalendar(staff, selectedStaffId);
  const resources = filteredStaff.map((staff) => ({
    ...staff,
    title: staff.alias,
  }));

  if (resources.length === 0) {
    return <div>There are no available times for selected parameters</div>;
  }

  return (
    <CustomTimeGrid
      {...props}
      data-testid="allRosteredWeekView"
      localizer={localizer}
      range={range}
      resources={resources}
      step={1440}
      timeslots={1}
    />
  );
}

AllRosteredCalendarView.range = WeekView.range
AllRosteredCalendarView.navigate = WeekView.navigate
AllRosteredCalendarView.title = WeekView.title

export default AllRosteredCalendarView
