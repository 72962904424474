/*
Duplicate file server/booking-question-settings.js
*/
export default {
  onlineAndCalendar: {
    name: 'Online and via calendar',
    status: 'onlineAndCalendar',
  },
  online: {
    name: 'Online only',
    status: 'online',
  },
  calendar: {
    name: 'Calendar only',
    status: 'calendar',
  },
  notRequired: {
    name: 'Not required',
    status: 'notRequired',
  },
};
