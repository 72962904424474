import { Fragment } from 'react';
import { Grid, Typography, FormControl } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import InputLabel from '../InputLabel';
import Textarea from '../../../../shared_components/Textarea';
import { checkServiceQuestionForCalendar } from '../../utils/serviceUtils';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(0, 3),
  },
  bookingQuestionBox: {
    marginTop: theme.spacing(1/2),
    width: '50%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  textarea: {
    marginTop: theme.spacing(),
    width: '100%',
  },
}));

const Requirement = (props) => {
  const {
    appointment: {
      isChangeable,
      serviceBookingQuestion,
      serviceBookingAnswer,
    },
    handleChangeAppointment,
    appointmentErrors,
    selectedService,
  } = props;
  const {classes} = useStyles();

  return (
    <Grid className={classes.root}>
      {(
        serviceBookingQuestion ||
        checkServiceQuestionForCalendar(selectedService)
      ) && (
        <Fragment>
          <Typography variant="subtitle1">
            Booking Question
          </Typography>
          <FormControl
            className={classes.bookingQuestionBox}
            error={appointmentErrors.serviceBookingAnswer}
          >
            <InputLabel focused={false}>
              {serviceBookingQuestion}
            </InputLabel>
            <Textarea
              id="serviceBookingAnswer"
              name="serviceBookingAnswer"
              value={serviceBookingAnswer}
              onChange={({ target }) => {
                return handleChangeAppointment(target.name, target.value);
              }}
              disabled={!isChangeable}
              minRows="3"
              className={classes.textarea}
            />
          </FormControl>
        </Fragment>
      )}
    </Grid>
  );
};

export default Requirement;
