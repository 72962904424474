import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  eventRoot: {},
  eventLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    flex: 'none',
    paddingRight: '5px',
    width: 'auto',
    whiteSpace: 'nowrap',
    borderLeft: '4px solid',
    padding: '0 4px'
  }
});

const MonthEvent = (props) => {
  const {
    classes, title, event
  } = props;

  return (
    <div className={classes.eventRoot} data-testid={`appointmentEvent-${event.id}`}>
      <div className={classes.eventLabel}>{title}</div>
    </div>
  );
}

export default withStyles(MonthEvent, styles);
