import {
  Grid,
  FormControl,
} from '@mui/material';
import { sortBy, groupBy } from 'lodash';
import {
  ErrorOutlineTwoTone as ExclamationMarkIcon,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import {
  prepareTimesArray,
  prepareDurationsArray,
} from '../../../../shared_client_utils/dateUtils';
import InputLabel from '../InputLabel';
import Select from '../Select';
import MoneyInput from '../MoneyInput';
import Tooltip from '../Tooltip';
import { formatMoney } from '../../../../shared_client_utils/moneyUtils';

const useStyles = makeStyles()((theme) => ({
  root: {
    justifyContent: 'space-between',
  },
  inputBox: {
    flex: 1,
    marginRight: theme.spacing(2),

    '&:last-of-type': {
      marginRight: 0,
    },

    [theme.breakpoints.down('md')]: {
      flex: 'unset',
      width: '100%',
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  wideInputBox: {
    flex: 2,

    [theme.breakpoints.down('md')]: {
      flex: 'unset',
    },
  },
  select: {
    width: '100%',
  },
  label: {
    color: '#808190',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(),
    pointerEvents: 'auto',
  },
  durationTitleBox: {
    alignItems: 'center',
  },
  exclamationMarkBox: {
    height: theme.spacing(2),
    width: 'auto',
    marginLeft: '6px',
  },
  exclamationMarkIcon: {
    height: '100%',
    width: 'auto',
  },
}));

const durationOptions = prepareDurationsArray();

const ServiceRow = (props) => {
  const {
    handleChangeServiceRow,
    handleChangeService,
    appointmentErrors,
    workingStaff,
    selectedStaff,
    services = [],
    selectedService,
    hasServiceExtraTime,
    serviceExtraTimeTooltipText,
    resourceItems,
    selectedResourceItem,
    calendarSettings,
    appointment: {
      duration,
      time,
      currency,
      priceMoney,
      isChangeable,
    },
  } = props;
  const sortedServices = sortBy(services, ['serviceCategoryOrder', 'order'])
  const timeOptions = prepareTimesArray(calendarSettings?.intervals);
  const {classes, cx} = useStyles();

  const selectedTime = timeOptions.find(({ value }) => value === time);
  const selectedDuration = durationOptions.find(({ value }) => {
    return value === duration;
  });

  return (
    <Grid container className={classes.root}>
      <FormControl
        className={cx(classes.inputBox, classes.wideInputBox)}
        title={selectedService && selectedService.label}
        error={appointmentErrors.serviceId}
      >
        <InputLabel
          shrink
          htmlFor="serviceId"
          className={classes.label}
          focused={false}
        >
          Service
        </InputLabel>
        <Select
          name="serviceId"
          disabled={!isChangeable}
          onChange={handleChangeService}
          className={classes.select}
          options={sortedServices}
          groupBy={(option) => option.serviceCategoryName}
          value={selectedService}
          error={appointmentErrors.serviceId}
        />
      </FormControl>

      <FormControl
        className={cx(classes.inputBox, classes.wideInputBox)}
        title={selectedStaff && selectedStaff.label}
        error={appointmentErrors.staffId}
      >
        <InputLabel
          shrink
          htmlFor="staff"
          className={classes.label}
          focused={false}
        >
          Staff
        </InputLabel>
        <Select
          disabled
          name="staffId"
          // onChange={(option) => {
          //   handleChangeServiceRow('staffId', option.value);
          // }}
          className={classes.select}
          options={workingStaff}
          value={selectedStaff}
          error={appointmentErrors.staffId}
        />
      </FormControl>
      <FormControl
        className={cx(classes.inputBox, classes.wideInputBox)}
        error={appointmentErrors.resourceItemId}
      >
        <InputLabel
          shrink
          htmlFor="resourceItemId"
          className={classes.label}
          focused={false}
        >
          Resource
        </InputLabel>
        <Select
          name="resourceItemId"
          disabled={!isChangeable}
          onChange={(option) => {
            handleChangeServiceRow('resourceItemId', option.value);
          }}
          className={classes.select}
          options={resourceItems}
          groupBy={(option) => option.resourceName}
          value={selectedResourceItem}
          error={appointmentErrors.resourceItemId}
        />
      </FormControl>
      <FormControl
        className={classes.inputBox}
        error={appointmentErrors.time}
      >
        <InputLabel
          shrink
          htmlFor="time"
          className={classes.label}
          focused={false}
        >
          Time
        </InputLabel>
        <Select
          id="time"
          disabled={!isChangeable}
          onChange={(option) => {
            handleChangeServiceRow('time', option.value);
          }}
          className={classes.select}
          options={timeOptions}
          value={selectedTime || null}
          error={appointmentErrors.time}
        />
      </FormControl>

      <FormControl
        className={classes.inputBox}
        error={appointmentErrors.duration}
      >
        <InputLabel
          shrink
          htmlFor="duration"
          className={classes.label}
          focused={false}
        >
          <Grid container className={classes.durationTitleBox}>
            <Grid item>Duration</Grid>
            {hasServiceExtraTime && (
              <div className={classes.exclamationMarkBox}>
                <Tooltip title={serviceExtraTimeTooltipText}>
                  <ExclamationMarkIcon
                    className={classes.exclamationMarkIcon}
                  />
                </Tooltip>
              </div>
            )}
          </Grid>
        </InputLabel>
        <Select
          disabled
          id="duration"
          onChange={() => {}}
          className={classes.select}
          options={durationOptions}
          value={selectedDuration}
          error={appointmentErrors.duration}
        />
      </FormControl>

      <FormControl className={classes.inputBox}>
        <InputLabel
          shrink
          htmlFor="price"
          className={classes.label}
          focused={false}
        >
          Price
        </InputLabel>
        <MoneyInput
          disabled
          id="price"
          name="price"
          value={priceMoney && formatMoney(priceMoney)}
          currency={currency}
          onChange={() => {}}
          className={classes.moneyInput}
        />
      </FormControl>
    </Grid>
  );
};

export default ServiceRow;
