import moment from 'moment';
import { isEmail } from 'validator';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const formatClientDatetime = (datetimeTz) => {
  return moment(datetimeTz).format('DD.MM.YYYY');
};

export const formatUserDateTime = (dateTimeTz) => {
  return moment(dateTimeTz).format('DD.MM.YY HH:mm');
};

export const clientErrorsMap = {
  firstName: {
    isValid: value => value && value.length > 0,
  },
  lastName: {
    isValid: value => value && value.length > 0,
  },
  email: {
    isValid: value => value && isEmail(value),
  },
  mobileNumber: {
    isValid: value => value && isValidPhoneNumber(value),
  },
};

export const recipientErrorsMap = {
  recipientEmail: {
    isValid: value => value && isEmail(value),
  },
  recipientName: {
    isValid: value => value && value.length > 0,
  },
};
