import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Textarea from '../../../../shared_components/Textarea';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(0, 3),
  },
  textarea: {
    width: '100%',
  },
}));

const Notes = (props) => {
  const { appointment, handleChangeAppointment } = props;
  const {classes} = useStyles();

  return (
    <Grid className={classes.root}>
      <Textarea
        id="notes"
        name="notes"
        value={appointment.notes}
        onChange={({ target }) => {
          handleChangeAppointment(target.name, target.value);
        }}
        disabled={!appointment.isChangeable}
        className={classes.textarea}
        minRows="15"
      />
    </Grid>
  );
};

export default Notes;
